var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FormulaBanner',{attrs:{"title":_vm.title,"formulaCode":_vm.formulaCode}}),_c('div',{staticClass:"index"},[_c('div',[_c('div',{staticClass:"top_nav"},[_c('span'),_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"formBody"},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('MyPatient',{attrs:{"memberId":_vm.memberId,"selectValue":_vm.selectValue},on:{"change":_vm.userChange}}),_c('van-field',{attrs:{"rules":[
              { required: true, message: '请填写' },
              {
                pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/,
                message: '请填写数字',
              } ]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"titles"},[_c('span',{staticClass:"titlesName"},[_vm._v("舒张末期容积（EDV）"),_c('i',[_vm._v("*")])]),_c('span',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticClass:"information"},[_c('van-cell-group',{staticClass:"vanCell"},[_c('van-field',{attrs:{"placeholder":"请输入"},model:{value:(_vm.EDV),callback:function ($$v) {_vm.EDV=$$v},expression:"EDV"}})],1)],1),_c('div',{staticClass:"unit"},[_c('span',[_vm._v("mL")])])])])]},proxy:true}]),model:{value:(_vm.EDV),callback:function ($$v) {_vm.EDV=$$v},expression:"EDV"}}),_c('van-field',{attrs:{"rules":[
              { required: true, message: '请填写' },
              {
                pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/,
                message: '请填写数字',
              } ]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"titles"},[_c('span',{staticClass:"titlesName"},[_vm._v("收缩末期容积（ESV）"),_c('i',[_vm._v("*")])]),_c('span',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"information"},[_c('van-cell-group',{staticClass:"vanCell"},[_c('van-field',{attrs:{"placeholder":"请输入"},model:{value:(_vm.ESV),callback:function ($$v) {_vm.ESV=$$v},expression:"ESV"}})],1)],1),_c('div',{staticClass:"unit"},[_c('span',[_vm._v("mL")])])])])]},proxy:true}]),model:{value:(_vm.ESV),callback:function ($$v) {_vm.ESV=$$v},expression:"ESV"}}),(_vm.compute === true)?_c('div',{staticStyle:{"margin":"16px","display":"flex","justify-content":"center"}},[_c('van-button',{staticClass:"formula-template-button",staticStyle:{"width":"90px"},attrs:{"block":"","type":"info","native-type":"submit"}},[_vm._v("提交")])],1):_vm._e()],1)],1)]),(_vm.show)?_c('CalculationResults',{attrs:{"countText":_vm.countTexText}}):_vm._e(),_c('FormulaExplain',{attrs:{"explain-data":_vm.StandardDescription}}),_c('FormulaReferences',{attrs:{"references-data":_vm.references}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }